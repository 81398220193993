import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

export const MainSection = ({ images, heading, paragraph, page }) => {
  const [mobileImage, desktopImage] = images

  return (
    <S.Container>
      <S.Wrapper page={page}>
        <S.Heading page={page}>{heading}</S.Heading>
        <S.Paragraph page={page}>{paragraph}</S.Paragraph>
        <S.Button to='/oferta'>Więcej &gt;</S.Button>
      </S.Wrapper>
      <S.HeroImageMobile fluid={mobileImage.fluid} alt='Zginanie blachy specialną maszyną w wersji mobilnej' />
      <S.HeroImageDesktop
        fluid={desktopImage.fluid}
        alt='Zginanie blachy specialną maszyną w wersji webowej'
        style={{ position: 'absolute' }}
        page={page}
      />
    </S.Container>
  )
}

MainSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired
}
