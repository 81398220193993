import React from 'react'

import { Wrapper } from 'src/components/common/wrapper/Wrapper'

import * as S from './styles'
import { MENU_LINKS } from './links'

export const Footer = () => (
  <S.FooterContainer>
    <S.Wrapper>
      <S.Contact>
        <S.Heading as='h4' h4='true'>
          Kontakt
        </S.Heading>
        <S.Paragraph>
          <span>693-829-439</span>
          <br />
          biuro@kuliberda.tech
          <br />
          www.kuliberda.tech
        </S.Paragraph>
        <S.Line />
      </S.Contact>
      <S.Localization>
        <S.Heading as='h4' h4='true'>
          Lokalizacja
        </S.Heading>
        <S.Paragraph>
          Orpelów Numerki
          <br />
          <S.Line />
          <span>Godziny otwarcia:</span>
          <br />
          pon-pt: 8:00-16:00
        </S.Paragraph>
      </S.Localization>
      <S.Navigation>
        {MENU_LINKS.map(item => (
          <S.Item key={item.name}>
            <S.Link to={item.url} activeStyle={{ color: 'hsl(0, 100%, 67%)', fontWeight: '600' }}>
              {item.name}
            </S.Link>
          </S.Item>
        ))}
      </S.Navigation>
      <S.About>
        <S.Heading as='h3' h3='true'>
          O nas
        </S.Heading>
        <S.Paragraph about='true'>
          Na rynku od 2011 roku. Wcześniejsze 25-letnie doświadczenia w zakładach przetwórstwa spożywczego. Oferujemy
          naprawę oraz remonty maszyn. Budowę oraz wizualizacje systemów transportujących, produkcji, oraz wszelakich
          innych.
        </S.Paragraph>
        <S.Button to='/oferta'>Oferta &gt;</S.Button>
      </S.About>
    </S.Wrapper>
    <Wrapper>
      <S.Paragraph copy='true'>
        &copy; Kuliberda.tech - Wszelkie prawa zastrzeżone. Made by{' '}
        <a href='https://www.linkedin.com/in/kacper-%C5%82ochowski/' rel='author'>
          Kacper Łochowski
        </a>
        .
      </S.Paragraph>
    </Wrapper>
  </S.FooterContainer>
)
