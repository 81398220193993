import styled from 'styled-components'

import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'
import { device } from 'src/themes/breakpoints'

export const Header = styled.header`
  z-index: 9999;
  width: 100%;
  height: 5rem;
  background: ${({ theme }) => theme.colors.primaryWhite};
  box-shadow: 0 4px 24px ${({ theme }) => theme.colors.boxShadow};
  position: fixed;

  @media ${device.tablet} {
    height: 9rem;
  }
`

export const Wrapper = styled(WrapperComponent)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
