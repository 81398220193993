import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import Settings from 'src/images/settings.svg'
import { device } from 'src/themes/breakpoints'

export const Navigation = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.colors.bgBlue} url(${Settings}) no-repeat center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: ${({ isOpen }) => (isOpen ? 'all 0.3s ease' : 'all 0.3s 0.8s ease')};
  transition-property: opacity, transform;

  @media ${device.tablet} {
    height: 100%;
    position: relative;
    left: unset;
    top: unset;
    height: auto;
    width: auto;
    flex-direction: row;
    transform: unset;
    opacity: 1;
    background: transparent;
    transition: unset;
  }
`

export const List = styled.ul`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '10%')});
  transition: ${({ isOpen }) => (isOpen ? 'all 0.5s 0.3s ease' : 'all 0.5s 0s ease')};
  transition-property: opacity, transform;

  @media ${device.header} {
    margin-top: 2rem;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    opacity: 1;
  }
`

export const Item = styled.li`
  text-align: center;
  margin-bottom: 2rem;

  :last-child {
    margin-bottom: 4rem;
  }

  @media (min-height: 470px) {
    margin-bottom: 3rem;

    :last-child {
      margin-bottom: 7rem;
    }
  }

  @media ${device.tablet} {
    margin-bottom: 0;
    margin-right: 3rem;

    :last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
`

export const Link = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.primaryWhite};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fonts.size.m};
  text-transform: capitalize;
  font-weight: ${({ theme }) => theme.fonts.weight.semi};

  @media (min-height: 470px) {
    font-size: ${({ theme }) => theme.fonts.size.xl};
  }

  @media ${device.tablet} {
    color: ${({ theme }) => theme.colors.fontGray};
    font-size: ${({ theme }) => theme.fonts.size.s};
  }
`

export const ButtonContainer = styled.div`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-10%')});
  transition: ${({ isOpen }) => (isOpen ? 'all 0.5s 0.8s ease' : 'all 0.5s 0.3s ease')};
  transition-property: opacity, transform;

  @media ${device.tablet} {
    display: none;
  }
`
