import { createGlobalStyle } from 'styled-components'

import regular from '../fonts/montserrat-v14-latin-ext-regular.woff2'
import regularWoff from '../fonts/montserrat-v14-latin-ext-regular.woff'
import semibold from '../fonts/montserrat-v14-latin-ext-600.woff2'
import semiboldWoff from '../fonts/montserrat-v14-latin-ext-600.woff'
import bold from '../fonts/montserrat-v14-latin-ext-700.woff2'
import boldWoff from '../fonts/montserrat-v14-latin-ext-700.woff'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url(${regular}) format('woff2'),
        url(${regularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url(${semibold}) format('woff2'),
        url(${semiboldWoff}) format('woff');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url(${bold}) format('woff2'),
        url(${boldWoff}) format('woff');
  }

  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
  }
`

export default GlobalStyles
