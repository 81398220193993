import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'src/components/common/button/Button'

import { MENU_LINKS } from './links'
import * as S from './styles'

export const Navigation = ({ isOpen }) => (
  <S.Navigation isOpen={isOpen}>
    <S.List isOpen={isOpen}>
      {MENU_LINKS.map(item => (
        <S.Item key={item.name}>
          <S.Link to={item.url} activeStyle={{ color: 'hsl(0, 100%, 67%)' }}>
            {item.name}
          </S.Link>
        </S.Item>
      ))}
    </S.List>
    <S.ButtonContainer isOpen={isOpen}>
      <Button to='/kontakt'>Skontaktuj się &gt;</Button>
    </S.ButtonContainer>
  </S.Navigation>
)

Navigation.propTypes = { isOpen: PropTypes.bool }

Navigation.defaultProps = { isOpen: false }
