const Theme = {
  fonts: {
    size: {
      xxs: '1rem',
      xs: '1.2rem',
      s: '1.4rem',
      m: '1.6rem',
      l: '1.8rem',
      xl: '2rem',
      xxl: '2.4rem',
      xxxl: '3.2rem',
      xxxxl: '4.5rem'
    },
    weight: {
      normal: 400,
      semi: 600,
      bold: 700
    }
  },
  colors: {
    primaryWhite: 'hsl(0, 0%, 100%)',
    fontGray: 'hsl(0, 0%, 31%)',
    bgGray: 'hsl(0, 0%, 98%)',
    bgBlue: 'hsl(220, 77%, 33%)',
    btnRed: 'hsl(0, 100%, 67%)',
    boxShadow: 'hsla(0, 0%, 0%, 0.16)'
  }
}

export default Theme
