import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'

import * as S from './styles'

export const Logo = ({ isOpen }) => (
  <StaticQuery
    query={graphql`
      {
        allContentfulAsset(filter: { title: { in: ["logo", "logo-white"] } }) {
          nodes {
            file {
              url
            }
          }
        }
      }
    `}
    render={data => {
      const [normal, white] = data.allContentfulAsset.nodes

      return (
        <Link to='/'>
          <S.Image src={isOpen ? normal.file.url : white.file.url} alt='Logo firmy Kuliberda' />
        </Link>
      )
    }}
  />
)

Logo.propTypes = { isOpen: PropTypes.bool }

Logo.defaultProps = { isOpen: false }
