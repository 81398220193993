import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import Reset from 'src/themes/Reset'
import GlobalStyles from 'src/themes/GlobalStyles'
import Theme from 'src/themes/Theme'

import Header from 'src/components/common/header/Header'
import { Footer } from 'src/components/footer/Footer'

export const MainTemplate = ({ children }) => (
  <>
    <Reset />
    <GlobalStyles />
    <ThemeProvider theme={Theme}>
      <Header />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  </>
)

MainTemplate.propTypes = { children: PropTypes.node.isRequired }
