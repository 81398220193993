import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'
import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Paragraph as ParagraphComponent } from 'src/components/common/paragraph/Paragraph'
import { Button as ButtonComponent } from 'src/components/common/button/Button'

import { device } from 'src/themes/breakpoints'

export const Container = styled.section`
  padding-top: 8rem;
  position: relative;

  @media ${device.tablet} {
    padding-top: 12rem;
  }

  @media ${device.laptop} {
    max-height: 53rem;
    display: flex;
    align-items: center;
    height: 100vh;
  }

  @media ${device.laptopL} {
    max-height: 72rem;
  }

  @media ${device.laptopXl} {
    max-height: 88rem;
  }
`

export const Wrapper = styled(WrapperComponent)`
  ${({ page }) =>
    page === 'about' &&
    css`
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `}
`

export const Heading = styled(HeadingComponent)`
  margin-bottom: 1rem;

  @media ${device.mobileXl} {
    padding-right: 2rem;
    width: 50%;
  }

  @media ${device.laptop} {
    margin-bottom: 3rem;
  }

  ${({ page }) =>
    page === 'about' &&
    css`
      @media ${device.mobileXl} {
        padding-right: 0rem;
        padding-left: 2rem;
      }
    `}
`

export const Paragraph = styled(ParagraphComponent)`
  margin-bottom: 3rem;

  @media ${device.mobileXl} {
    padding-right: 2rem;
    width: 50%;
  }

  @media ${device.laptop} {
    margin-bottom: 4rem;
  }

  ${({ page }) =>
    page === 'about' &&
    css`
      @media ${device.mobileXl} {
        padding-right: 0rem;
        padding-left: 2rem;
      }
    `}
`

export const Button = styled(ButtonComponent)`
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;
`

export const HeroImageMobile = styled(Img)`
  max-width: 55rem;

  @media ${device.mobileXl} {
    display: none;
  }
`

export const HeroImageDesktop = styled(Img)`
  display: none;
  @media ${device.mobileXl} {
    top: 5rem;
    right: 0;
    width: 50%;
    display: block;
    flex-shrink: 0;
    max-width: 90rem;
  }

  @media ${device.tablet} {
    top: 9rem;
  }

  ${({ page }) =>
    page === 'about' &&
    css`
      @media ${device.mobileXl} {
        right: unset;
        left: 0;
      }
    `}
`
