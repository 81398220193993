import styled from 'styled-components'
import { device } from 'src/themes/breakpoints'

export const Image = styled.img`
  position: relative;
  z-index: 10;
  height: 3rem;

  @media ${device.tablet} {
    height: 5rem;
  }
`
