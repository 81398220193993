import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'
import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Paragraph as ParagraphComponent } from 'src/components/common/paragraph/Paragraph'
import { Button as ButtonComponent } from 'src/components/common/button/Button'

import { device } from 'src/themes/breakpoints'

import Settings from 'src/images/settings.svg'
import SettingsHorizontal from 'src/images/settings-horizontal.svg'

export const FooterContainer = styled.footer`
  padding: 3rem 0;
  background: ${({ theme }) => theme.colors.bgBlue} url(${Settings}) no-repeat center;

  @media ${device.tablet} {
    background: ${({ theme }) => theme.colors.bgBlue} url(${SettingsHorizontal}) no-repeat center;
  }
`

export const Wrapper = styled(WrapperComponent)`
  @media ${device.tablet} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

export const Heading = styled(HeadingComponent)`
  color: ${({ theme }) => theme.colors.primaryWhite};
  margin-bottom: 1rem;
`

export const Button = styled(ButtonComponent)`
  margin-bottom: 4rem;

  @media ${device.tablet} {
    margin-bottom: 2rem;
  }
`

export const Paragraph = styled(ParagraphComponent)`
  font-size: ${({ theme }) => theme.fonts.size.s};
  color: ${({ theme }) => theme.colors.primaryWhite};
  line-height: 1.8;

  span {
    font-weight: ${({ theme }) => theme.fonts.weight.semi};
  }

  ${({ about }) =>
    about &&
    css`
      margin-bottom: 3rem;
    `}

  ${({ copy }) =>
    copy &&
    css`
      font-size: ${({ theme }) => theme.fonts.size.xxs};

      a {
        color: ${({ theme }) => theme.colors.primaryWhite};
      }
    `}
`

export const Contact = styled.div`
  margin-bottom: 3rem;

  @media ${device.tablet} {
  }
`
export const Localization = styled.div`
  margin-bottom: 4rem;

  @media ${device.tablet} {
    margin-right: 2rem;
  }
`

export const About = styled.div`
  @media ${device.tablet} {
    flex-basis: 45%;
  }

  @media ${device.laptop} {
    flex-basis: 35%;
  }
`

export const Navigation = styled.ul`
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`

export const Line = styled.span`
  background: ${({ theme }) => theme.colors.primaryWhite};
  display: block;
  width: 7rem;
  height: 0.2rem;
  border-radius: 2rem;
  bottom: 0;
  left: 0;
  margin: 0.8rem 0;
`

export const Item = styled.li`
  margin-bottom: 1.5rem;

  :last-child {
    margin-bottom: 0;
  }
`

export const Link = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.primaryWhite};
  text-decoration: none;
  text-transform: capitalize;
`
