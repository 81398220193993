const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXl: '550px',
  tablet: '768px',
  tabletL: '960px',
  laptop: '1024px',
  laptopM: '1366px',
  laptopL: '1440px',
  laptopXl: '1800px',
  desktop: '2560px'
}

export const device = {
  header: `(max-height: 350px) and (max-width: 767px)`,
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXl: `(min-width: ${size.mobileXl})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  laptopXl: `(min-width: ${size.laptopXl})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
}
