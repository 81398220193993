import React, { useState } from 'react'

import { Logo } from './logo/Logo'
import { Habmurger } from './hamburger/Hamburger'
import { Navigation } from './navigation/Navigation'

import * as S from './styles'

const Header = () => {
  const [isMenuOpen, setMenuState] = useState(false)

  const toggleMobileMenu = () => {
    setMenuState(!isMenuOpen)
  }

  typeof window !== 'undefined' &&
    window.addEventListener('resize', () => {
      window.innerWidth >= 768 && isMenuOpen && toggleMobileMenu()
    })

  return (
    <S.Header>
      <S.Wrapper>
        <Logo isOpen={isMenuOpen} />
        <Habmurger onClick={toggleMobileMenu} isOpen={isMenuOpen} />
        <Navigation isOpen={isMenuOpen} />
      </S.Wrapper>
    </S.Header>
  )
}

export default Header
