import styled from 'styled-components'
import { Link } from 'gatsby'

import { device } from '../../../themes/breakpoints'

export const Button = styled(Link)`
  width: 260px;
  display: block;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.size.m};
  padding: 1.5rem 0;
  background: ${({ theme }) => theme.colors.btnRed};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryWhite};
  font-weight: ${({ theme }) => theme.fonts.weight.semi};
  border-radius: 5rem;
  box-shadow: 0 4px 24px ${({ theme }) => theme.colors.boxShadow};
  cursor: pointer;

  @media ${device.mobileXl} {
    font-size: ${({ theme }) => theme.fonts.size.s};
    width: 20rem;
    position: static;
    transform: unset;
  }

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fonts.size.m};
    width: 26rem;
  }
`
