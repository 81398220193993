import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

export const Habmurger = props => {
  const { isOpen } = props
  return (
    <S.Wrapper {...props}>
      <S.Line isOpen={isOpen} />
      <S.Line isOpen={isOpen} />
      <S.Line isOpen={isOpen} />
    </S.Wrapper>
  )
}

Habmurger.propTypes = { isOpen: PropTypes.bool }

Habmurger.defaultProps = { isOpen: false }
