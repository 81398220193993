import styled, { css } from 'styled-components'

import { device } from 'src/themes/breakpoints'

export const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.fontGray};
  font-size: ${({ theme }) => theme.fonts.size.xxxl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  letter-spacing: 0.16rem;
  line-height: 1.25;

  @media ${device.mobileXl} {
    font-size: ${({ theme }) => theme.fonts.size.xl};
  }

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fonts.size.xxxl};
  }

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fonts.size.xxxxl};
  }

  ${({ h2 }) =>
    h2 &&
    css`
      font-size: ${({ theme }) => theme.fonts.size.xxl};

      @media ${device.laptop} {
        font-size: ${({ theme }) => theme.fonts.size.xxxl};
      }
    `}

  ${({ h3 }) =>
    h3 &&
    css`
      font-size: ${({ theme }) => theme.fonts.size.xl};
      font-weight: ${({ theme }) => theme.fonts.weight.bold};

      @media ${device.laptop} {
        font-size: ${({ theme }) => theme.fonts.size.xxxl};
      }
    `}

  ${({ h4 }) =>
    h4 &&
    css`
      font-size: ${({ theme }) => theme.fonts.size.s};
      font-weight: ${({ theme }) => theme.fonts.weight.semi};

      @media ${device.mobileXl} {
        font-size: ${({ theme }) => theme.fonts.size.s};
      }
    `}
`
