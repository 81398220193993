import styled from 'styled-components'
import { device } from 'src/themes/breakpoints'

export const Wrapper = styled.div`
  overflow: hidden;
  width: calc(2rem + 1rem);
  height: calc(1.4rem + 1rem);
  position: relative;
  right: -0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 10;
`

export const Line = styled.span`
  transform-origin: center;
  position: absolute;
  width: calc(100% - 1rem);
  height: 0.2rem;
  border-radius: 2rem;
  background: ${({ theme, isOpen }) => (isOpen ? theme.colors.primaryWhite : theme.colors.bgBlue)};
  transition: ${({ isOpen }) => (isOpen ? 'all 0.3s ease' : 'all 0.3s 0.8s ease')};

  :nth-child(1) {
    top: 0.5rem;
    transform: ${({ isOpen }) => (isOpen ? 'translateY(6px) rotate(45deg)' : 'translateY(0) rotate(0)')};
  }

  :nth-child(2) {
    top: 50%;
    opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    transform: ${({ isOpen }) => (isOpen ? 'translate(calc(100% + 0.5rem), -50%)' : 'translate(-0.5rem, -50%)')};
  }

  :nth-child(3) {
    bottom: 0.5rem;
    transform: ${({ isOpen }) => (isOpen ? 'translateY(-6px) rotate(-45deg)' : 'translateY(0) rotate(0)')};
  }

  @media ${device.tablet} {
    display: none;
  }
`
